import { template as template_3ae1059669864fc48527a6388ee2fd88 } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_3ae1059669864fc48527a6388ee2fd88(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
