import { template as template_096da28a4f924a4ea49fab2005c41ea0 } from "@ember/template-compiler";
const FKLabel = template_096da28a4f924a4ea49fab2005c41ea0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
