import { template as template_29cc466d9ef44cfb819b0c2653c5ebdc } from "@ember/template-compiler";
const WelcomeHeader = template_29cc466d9ef44cfb819b0c2653c5ebdc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
