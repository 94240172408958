import { template as template_840eddeb03d04b478ee987d987275fac } from "@ember/template-compiler";
const FKControlMenuContainer = template_840eddeb03d04b478ee987d987275fac(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
